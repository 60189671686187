import { memo } from "react";
// @mui
import { useTheme } from "@mui/material/styles";
import { Box, BoxProps } from "@mui/material";

// ----------------------------------------------------------------------

function LocationErrorIlustrate({ ...other }: BoxProps) {
  const theme = useTheme();

  const PRIMARY_MAIN = theme.palette.primary.main;

  const WARNING_LIGHT = theme.palette.warning.light;

  return (
    <Box {...other}>
      <svg
        width="645"
        height="480"
        viewBox="0 0 645 480"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_2535_31098)">
          <path
            d="M644.105 421.824H0V422.147H644.105V421.824Z"
            fill="#EBEBEB"
          />
          <path
            d="M426.681 446.056H415.486V446.378H426.681V446.056Z"
            fill="#EBEBEB"
          />
          <path
            d="M223.144 430.043H193.283V430.365H223.144V430.043Z"
            fill="#EBEBEB"
          />
          <path
            d="M590.992 436.948H517.989V437.27H590.992V436.948Z"
            fill="#EBEBEB"
          />
          <path
            d="M128.035 436.948H55.0323V437.27H128.035V436.948Z"
            fill="#EBEBEB"
          />
          <path
            d="M305.319 364.37H56.5782C52.5204 364.37 49.2225 361.072 49.2225 357.015V7.35568C49.2225 3.29782 52.5204 0 56.5782 0H305.332C309.389 0 312.687 3.29782 312.687 7.35568V357.015C312.687 361.072 309.389 364.37 305.332 364.37H305.319ZM56.5782 0.322053C52.7007 0.322053 49.5446 3.47817 49.5446 7.35568V357.015C49.5446 360.892 52.7007 364.048 56.5782 364.048H305.332C309.209 364.048 312.365 360.892 312.365 357.015V7.35568C312.365 3.47817 309.209 0.322053 305.332 0.322053H56.5782Z"
            fill="#EBEBEB"
          />
          <path
            d="M583.959 364.37H335.205C331.147 364.37 327.849 361.072 327.849 357.015V7.35568C327.849 3.29782 331.147 0 335.205 0H583.959C588.016 0 591.314 3.29782 591.314 7.35568V357.015C591.314 361.072 588.016 364.37 583.959 364.37ZM335.205 0.322053C331.328 0.322053 328.172 3.47817 328.172 7.35568V357.015C328.172 360.892 331.328 364.048 335.205 364.048H583.959C587.836 364.048 590.992 360.892 590.992 357.015V7.35568C590.992 3.47817 587.836 0.322053 583.959 0.322053H335.205Z"
            fill="#EBEBEB"
          />
          <path
            d="M277.983 32.347H104.448V184.691H277.983V32.347Z"
            fill="#E6E6E6"
          />
          <path
            d="M296.585 26.4212H93.215C92.2617 26.4212 91.4888 27.1941 91.4888 28.1474C91.4888 29.1007 92.2617 29.8736 93.215 29.8736H296.585C297.538 29.8736 298.311 29.1007 298.311 28.1474C298.311 27.1941 297.538 26.4212 296.585 26.4212Z"
            fill="#F5F5F5"
          />
          <path
            d="M285.352 32.347H107.888V184.691H285.352V32.347Z"
            fill="#EBEBEB"
          />
          <path
            d="M275.046 174.385V42.6526L118.206 42.6526V174.385H275.046Z"
            fill="#FAFAFA"
          />
          <path
            d="M175.029 174.385L193.09 42.6526H221.65L203.589 174.385H175.029Z"
            fill="white"
          />
          <path
            d="M209.734 174.385L227.781 42.6526H238.924L220.864 174.385H209.734Z"
            fill="white"
          />
          <path
            d="M275.046 174.385V42.6526H273.732V174.385H275.046Z"
            fill="#E6E6E6"
          />
          <g opacity="0.4">
            <path
              d="M118.322 23.3295H100.583V270.576H118.322V23.3295Z"
              fill="#F0F0F0"
            />
            <path
              d="M125.24 23.3295H111.417V270.576H125.24V23.3295Z"
              fill="#E6E6E6"
            />
            <path
              d="M136.061 23.3295H118.322V270.576H136.061V23.3295Z"
              fill="#F0F0F0"
            />
            <path
              d="M142.978 23.3295H129.156V270.576H142.978V23.3295Z"
              fill="#E6E6E6"
            />
            <path
              d="M153.799 23.3295H136.061V270.576H153.799V23.3295Z"
              fill="#F0F0F0"
            />
          </g>
          <g opacity="0.4">
            <path
              d="M268.45 23.3295H258.222V270.576H268.45V23.3295Z"
              fill="#F0F0F0"
            />
            <path
              d="M272.418 23.3295H264.457V270.576H272.418V23.3295Z"
              fill="#E6E6E6"
            />
            <path
              d="M278.666 23.3295H268.437V270.576H278.666V23.3295Z"
              fill="#F0F0F0"
            />
            <path
              d="M282.646 23.3295H274.685V270.576H282.646V23.3295Z"
              fill="#E6E6E6"
            />
            <path
              d="M288.894 23.3295H278.666V270.576H288.894V23.3295Z"
              fill="#F0F0F0"
            />
          </g>
          <path
            d="M565.486 421.824V131.178H472.297V421.824H565.486Z"
            fill="#F0F0F0"
          />
          <path
            d="M560.371 224.02V138.457H477.411V224.02H560.371Z"
            fill="#E6E6E6"
          />
          <path
            d="M560.371 317.26V231.697H477.411V317.26H560.371Z"
            fill="#E6E6E6"
          />
          <path
            d="M560.371 410.501V324.938H477.411V410.501H560.371Z"
            fill="#E6E6E6"
          />
          <path
            d="M560.371 224.033V138.47H527.741V224.033H560.371Z"
            fill="#E0E0E0"
          />
          <path
            d="M560.371 317.26V231.697H527.741V317.26H560.371Z"
            fill="#E0E0E0"
          />
          <path
            d="M547.927 189.844H525.319V224.02H547.927V189.844Z"
            fill="#F0F0F0"
          />
          <path
            d="M515.787 224.02H525.332V189.844H515.787V224.02Z"
            fill="#E0E0E0"
          />
          <path
            d="M520.553 198.732H491.517V224.02H520.553V198.732Z"
            fill="#F0F0F0"
          />
          <path
            d="M479.253 224.02H491.517V198.732H479.253V224.02Z"
            fill="#E0E0E0"
          />
          <path
            d="M560.371 410.501V324.938H527.741V410.501H560.371Z"
            fill="#E0E0E0"
          />
          <path
            d="M472.297 421.824V131.178H446.996V421.824H472.297Z"
            fill="#F5F5F5"
          />
          <path
            d="M551.084 360.325H489.456V410.488H551.084V360.325Z"
            fill="#F0F0F0"
          />
          <path
            d="M477.411 410.488H489.455V360.325H477.411V410.488Z"
            fill="#E0E0E0"
          />
          <path
            d="M540.275 277.455H485.514V317.26H540.275V277.455Z"
            fill="#FAFAFA"
          />
          <path
            d="M477.411 317.26H485.514V277.455H477.411V317.26Z"
            fill="#F0F0F0"
          />
          <path
            d="M467.44 421.812V131.166H374.251V421.812H467.44Z"
            fill="#F0F0F0"
          />
          <path
            d="M462.313 224.02V138.457H379.352V224.02H462.313Z"
            fill="#E6E6E6"
          />
          <path
            d="M462.313 317.26V231.697H379.352V317.26H462.313Z"
            fill="#E6E6E6"
          />
          <path
            d="M462.326 410.488V324.925H379.365V410.488H462.326Z"
            fill="#E6E6E6"
          />
          <path
            d="M462.313 224.033V138.47H429.682V224.033H462.313Z"
            fill="#E0E0E0"
          />
          <path
            d="M462.326 317.26V231.698H429.695V317.26H462.326Z"
            fill="#E0E0E0"
          />
          <path
            d="M415.074 292.424H392.466V317.26H415.074V292.424Z"
            fill="#F0F0F0"
          />
          <path
            d="M382.92 317.248H392.466V292.411H382.92V317.248Z"
            fill="#E0E0E0"
          />
          <path
            d="M435.518 267.574H412.91V292.411H435.518V267.574Z"
            fill="#F0F0F0"
          />
          <path
            d="M403.364 292.424H412.91V267.587H403.364V292.424Z"
            fill="#E0E0E0"
          />
          <path
            d="M451.595 292.424H428.987V317.26H451.595V292.424Z"
            fill="#F0F0F0"
          />
          <path
            d="M419.441 317.248H428.987V292.411H419.441V317.248Z"
            fill="#E0E0E0"
          />
          <path
            d="M462.313 410.501V324.938H429.683V410.501H462.313Z"
            fill="#E0E0E0"
          />
          <path
            d="M374.238 421.824V131.178H348.937V421.824H374.238Z"
            fill="#F5F5F5"
          />
          <path
            d="M440.297 342.574H389.323V410.501H440.297V342.574Z"
            fill="#F0F0F0"
          />
          <path
            d="M379.352 410.488H389.323V342.561H379.352V410.488Z"
            fill="#E0E0E0"
          />
          <path
            d="M456.683 170.108H389.323V224.02H456.683V170.108Z"
            fill="#FAFAFA"
          />
          <path
            d="M379.352 224.02H389.323V170.108H379.352V224.02Z"
            fill="#F0F0F0"
          />
          <path
            d="M443.127 99.6455C444.762 93.1828 440.849 86.6181 434.386 84.9828C427.924 83.3475 421.359 87.2608 419.724 93.7235C418.088 100.186 422.002 106.751 428.464 108.386C434.927 110.022 441.492 106.108 443.127 99.6455Z"
            fill="#F5F5F5"
          />
          <path
            d="M462.12 94.5417C462.661 101.189 457.727 107.012 451.08 107.566C444.433 108.107 438.61 103.173 438.056 96.5256C437.515 89.8784 442.449 84.0557 449.096 83.5018C455.743 82.9607 461.566 87.8946 462.12 94.5417Z"
            fill="#E0E0E0"
          />
          <path
            d="M464.223 101.05C470.885 100.797 476.08 95.1926 475.828 88.531C475.575 81.8694 469.971 76.6737 463.309 76.9262C456.647 77.1786 451.452 82.7835 451.704 89.4451C451.957 96.1067 457.561 101.302 464.223 101.05Z"
            fill="#F5F5F5"
          />
          <path
            d="M481.055 111.098C487.716 110.845 492.912 105.24 492.66 98.5789C492.407 91.9173 486.802 86.7216 480.141 86.9741C473.479 87.2265 468.283 92.8314 468.536 99.493C468.788 106.155 474.393 111.35 481.055 111.098Z"
            fill="#E0E0E0"
          />
          <path
            d="M502.579 107.082C508.337 103.723 510.281 96.3319 506.922 90.5738C503.562 84.8157 496.171 82.8712 490.413 86.2305C484.655 89.5899 482.711 96.981 486.07 102.739C489.429 108.497 496.82 110.442 502.579 107.082Z"
            fill="#E0E0E0"
          />
          <path
            d="M515.439 97.6463C515.439 97.6463 514.035 131.178 460.162 131.178C406.289 131.178 404.885 97.6463 404.885 97.6463H515.439Z"
            fill="#F0F0F0"
          />
          <path
            d="M93.5112 284.308H78.6195V413.477H93.5112V284.308Z"
            fill="#F0F0F0"
          />
          <path
            d="M312.352 413.477H84.3391V421.657H312.352V413.477Z"
            fill="#F0F0F0"
          />
          <path
            d="M93.4984 413.477H318.059V284.308H93.4984L93.4984 413.477Z"
            fill="#F5F5F5"
          />
          <path
            d="M297.177 342.574H114.393C109.33 342.574 105.221 338.464 105.221 333.402V309.389C105.221 304.327 109.33 300.217 114.393 300.217H297.177C302.24 300.217 306.349 304.327 306.349 309.389V333.402C306.349 338.464 302.24 342.574 297.177 342.574Z"
            fill="#F0F0F0"
          />
          <path
            d="M297.177 397.58H114.393C109.33 397.58 105.221 393.471 105.221 388.408V364.396C105.221 359.333 109.33 355.224 114.393 355.224H297.177C302.24 355.224 306.349 359.333 306.349 364.396V388.408C306.349 393.471 302.24 397.58 297.177 397.58Z"
            fill="#F0F0F0"
          />
          <path
            d="M265.603 324.294H145.98C144.383 324.294 143.082 322.993 143.082 321.396C143.082 319.798 144.383 318.497 145.98 318.497H265.603C267.201 318.497 268.502 319.798 268.502 321.396C268.502 322.993 267.201 324.294 265.603 324.294Z"
            fill="#F5F5F5"
          />
          <path
            d="M265.603 379.301H145.98C144.383 379.301 143.082 378 143.082 376.402C143.082 374.805 144.383 373.504 145.98 373.504H265.603C267.201 373.504 268.502 374.805 268.502 376.402C268.502 378 267.201 379.301 265.603 379.301Z"
            fill="#F5F5F5"
          />
          <path
            d="M213.83 284.308H235.614V232.921H213.83V284.308Z"
            fill="#E0E0E0"
          />
          <path
            d="M249.05 232.921H235.614V284.308H249.05V232.921Z"
            fill="#EBEBEB"
          />
          <path
            d="M188.813 226.21H213.83L222.86 284.308H197.83L188.813 226.21Z"
            fill="#E6E6E6"
          />
          <path
            d="M224.651 226.21H213.83L222.86 284.308H233.681L224.651 226.21Z"
            fill="#F0F0F0"
          />
          <path
            d="M177.374 284.308H201.566V231.234H177.374V284.308Z"
            fill="#EBEBEB"
          />
          <path
            d="M213.83 231.234H201.566V284.308H213.83V231.234Z"
            fill="#F5F5F5"
          />
          <path
            d="M169.065 284.308H186.571V239.826H169.065V284.308Z"
            fill="#E0E0E0"
          />
          <path
            d="M198.835 239.826H186.571V284.308H198.835V239.826Z"
            fill="#E6E6E6"
          />
          <path
            d="M322.053 480C459.997 480 571.824 473.471 571.824 465.417C571.824 457.364 459.997 450.835 322.053 450.835C184.108 450.835 72.2815 457.364 72.2815 465.417C72.2815 473.471 184.108 480 322.053 480Z"
            fill="#F5F5F5"
          />
          <path
            d="M433.882 387.674H305.628C299.651 387.674 295.245 382.779 295.837 376.801L322.671 109.421C323.276 103.443 328.661 98.5481 334.638 98.5481H462.893C468.87 98.5481 473.276 103.443 472.683 109.421L445.85 376.801C445.244 382.779 439.859 387.674 433.882 387.674Z"
            fill="#263238"
          />
          <path
            d="M402.282 390.895H373.104V392.183H402.282V390.895Z"
            fill="#5E3377"
          />
          <path
            d="M421.18 390.895H411.88V392.183H421.18V390.895Z"
            fill="#5E3377"
          />
          <path
            d="M430.623 387.674H302.369C296.391 387.674 291.986 382.779 292.578 376.801L319.412 109.421C320.017 103.443 325.402 98.5481 331.379 98.5481H459.633C465.611 98.5481 470.016 103.443 469.424 109.421L442.59 376.801C441.985 382.779 436.6 387.674 430.623 387.674Z"
            fill="#5E3377"
          />
          <g opacity="0.4">
            <path
              opacity="0.2"
              d="M427.145 382.805H306.813C301.106 382.805 296.907 378.141 297.486 372.434L323.457 113.775C324.023 108.068 329.163 103.405 334.87 103.405H455.202C460.909 103.405 465.108 108.068 464.529 113.775L438.558 372.434C437.991 378.141 432.851 382.805 427.145 382.805Z"
              fill="white"
            />
            <mask
              id="mask0_2535_31098"
              // style="mask-type:luminance"
              maskUnits="userSpaceOnUse"
              x="297"
              y="103"
              width="168"
              height="280"
            >
              <path
                d="M427.145 382.805H306.813C301.106 382.805 296.907 378.141 297.486 372.434L323.457 113.775C324.023 108.068 329.163 103.405 334.87 103.405H455.202C460.909 103.405 465.108 108.068 464.529 113.775L438.558 372.434C437.991 378.141 432.851 382.805 427.145 382.805Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_2535_31098)">
              <path
                d="M241.243 303.464L235.974 300.849L371.017 65.6858L376.286 68.3138L241.243 303.464Z"
                fill="white"
              />
              <path
                d="M265.887 311.528L260.863 308.501L411.764 82.0461L416.8 85.0605L265.887 311.528Z"
                fill="white"
              />
              <path
                d="M282.659 361.665L277.558 358.754L454.996 81.5952L460.097 84.5066L282.659 361.665Z"
                fill="white"
              />
              <path
                d="M363.623 407.203L357.517 407.01L386.128 191.054L392.608 188.349L363.623 407.203Z"
                fill="white"
              />
              <path
                d="M338.013 447.55L332.925 444.639L510.363 167.48L515.464 170.392L338.013 447.55Z"
                fill="white"
              />
              <path
                d="M297.577 104.487L301.325 99.2824L552.758 264.663L549.022 269.88L297.577 104.487Z"
                fill="white"
              />
              <path
                d="M305.679 72.7581L306.749 71.135L565.473 225.978L564.404 227.601L305.679 72.7581Z"
                fill="white"
              />
              <path
                d="M344.364 189.199L345.897 186.881L378.128 206.178L376.595 208.484L344.364 189.199Z"
                fill="white"
              />
              <path
                d="M382.792 253.352L384.338 251.046L439.988 284.347L438.442 286.665L382.792 253.352Z"
                fill="white"
              />
              <path
                d="M289.023 342.664L290.556 340.358L364.692 384.724L363.159 387.043L289.023 342.664Z"
                fill="white"
              />
              <path
                d="M323.264 155.706L325.557 154.134L345.601 185.773L343.308 187.344L323.264 155.706Z"
                fill="white"
              />
              <path
                d="M338.039 32.4114L339.276 30.9042L575.276 216.303L574.039 217.811L338.039 32.4114Z"
                fill="white"
              />
            </g>
          </g>
          <path
            d="M396.292 321.859C395.004 334.664 383.578 345.047 370.773 345.047C357.968 345.047 348.628 334.664 349.917 321.859C352.261 298.517 376.75 285.506 376.75 285.506C376.75 285.506 398.637 298.517 396.292 321.859Z"
            fill="white"
          />
          <path
            opacity="0.4"
            d="M380.409 321.859C380.009 325.891 376.402 329.163 372.37 329.163C368.338 329.163 365.401 325.891 365.8 321.859C366.2 317.827 369.807 314.555 373.839 314.555C377.871 314.555 380.808 317.827 380.409 321.859Z"
            fill="#5E3377"
          />
          <path
            d="M377.626 278.176C377.626 278.176 377.6 278.176 377.587 278.176C376.879 278.15 376.376 277.558 376.467 276.849L387.249 192.755C387.519 190.668 388.292 188.581 389.477 186.752L418.333 142.257C418.733 141.639 418.591 140.853 418.011 140.505L388.473 122.573C387.893 122.225 387.751 121.427 388.163 120.821C388.576 120.203 389.387 119.984 389.967 120.345L419.506 138.264C421.232 139.294 421.67 141.652 420.472 143.494L391.616 187.989C390.663 189.47 390.031 191.145 389.825 192.832L379.043 276.927C378.953 277.622 378.322 278.176 377.626 278.176Z"
            fill="white"
          />
          <path
            d="M373.748 89.4662C374.779 79.1476 383.99 70.7872 394.308 70.7872C404.627 70.7872 412.15 79.1476 411.107 89.4662C409.213 108.274 389.49 118.747 389.49 118.747C389.49 118.747 371.868 108.261 373.748 89.4662Z"
            fill="#5E3377"
          />
          <path
            opacity="0.3"
            d="M373.748 89.4662C374.779 79.1476 383.99 70.7872 394.308 70.7872C404.627 70.7872 412.15 79.1476 411.107 89.4662C409.213 108.274 389.49 118.747 389.49 118.747C389.49 118.747 371.868 108.261 373.748 89.4662Z"
            fill="black"
          />
          <path
            d="M404.202 89.4662C403.545 95.9717 397.748 101.24 391.242 101.24C384.737 101.24 379.996 95.9717 380.653 89.4662C381.31 82.9607 387.107 77.692 393.613 77.692C400.118 77.692 404.859 82.9607 404.202 89.4662Z"
            fill="#5E3377"
          />
          <path
            d="M223.17 161.864C222.77 162.946 222.306 164.311 221.894 165.561C221.469 166.849 221.057 168.15 220.67 169.464C219.859 172.079 219.099 174.72 218.403 177.387C217.708 180.053 217.063 182.733 216.445 185.438L215.569 189.496L215.157 191.531L215.067 192.008L215.015 192.278C214.964 192.433 214.977 192.729 214.938 192.948C214.822 195.022 215.131 197.753 215.556 200.381C215.968 203.048 216.535 205.753 217.128 208.471C218.352 213.894 219.691 219.408 221.083 224.844L215.968 226.777C213.418 221.534 211.176 216.252 209.244 210.738C208.265 207.982 207.389 205.186 206.616 202.288C206.242 200.832 205.908 199.351 205.624 197.805C205.354 196.246 205.109 194.661 205.083 192.819C205.083 192.33 205.07 191.905 205.122 191.364L205.186 190.578L205.251 189.998L205.521 187.821C205.689 186.365 205.933 184.923 206.152 183.48C206.642 180.594 207.144 177.709 207.814 174.849C208.471 171.989 209.167 169.129 209.991 166.295C210.416 164.878 210.854 163.474 211.331 162.057C211.808 160.614 212.284 159.3 212.928 157.728L223.157 161.877L223.17 161.864Z"
            fill="#FFC3BD"
          />
          <path
            d="M215.737 225.952L213.933 233.591L218.725 239.234C218.725 239.234 225.076 220.786 218.377 221.675L215.749 225.952H215.737Z"
            fill="#FFC3BD"
          />
          <path
            d="M241.385 455.035C240.393 455.035 239.298 454.828 238.538 454.648C238.435 454.622 238.358 454.545 238.345 454.442C238.332 454.339 238.37 454.236 238.448 454.184C238.821 453.94 242.068 451.827 243.33 452.394C243.523 452.484 243.781 452.677 243.845 453.115C243.936 453.682 243.794 454.146 243.433 454.468C242.982 454.893 242.209 455.035 241.385 455.035ZM239.259 454.274C241.14 454.648 242.557 454.584 243.098 454.081C243.33 453.875 243.407 453.566 243.356 453.167C243.317 452.948 243.227 452.883 243.137 452.845C242.467 452.548 240.586 453.463 239.259 454.262V454.274Z"
            fill="#5E3377"
          />
          <path
            d="M238.602 454.648C238.602 454.648 238.512 454.648 238.474 454.609C238.396 454.558 238.345 454.481 238.345 454.39C238.345 454.262 238.332 451.157 239.491 450.114C239.8 449.843 240.161 449.727 240.573 449.766C241.217 449.843 241.398 450.242 241.436 450.474C241.694 451.685 239.749 454.017 238.731 454.597C238.693 454.622 238.654 454.635 238.602 454.635V454.648ZM240.393 450.281C240.187 450.281 239.994 450.358 239.826 450.5C239.131 451.118 238.912 452.87 238.86 453.901C239.878 453.076 241.089 451.337 240.934 450.59C240.908 450.474 240.831 450.32 240.509 450.281C240.47 450.281 240.432 450.281 240.393 450.281Z"
            fill="#5E3377"
          />
          <path
            d="M241.54 140.673C242.506 140.621 240.883 137.645 240.419 137.413C239.35 136.872 227.884 138.495 228.426 140.273C228.683 141.033 229.353 142.476 229.984 142.875C230.615 143.275 233.076 141.033 241.54 140.685V140.673Z"
            fill="#263238"
          />
          <path
            d="M233.772 147.693C233.45 148.608 231.041 146.238 230.938 145.722C230.706 144.55 235.395 133.948 236.953 134.966C237.623 135.417 238.821 136.46 239.027 137.169C239.234 137.877 236.412 139.642 233.772 147.693Z"
            fill="#263238"
          />
          <path
            d="M236.258 119.945C237.572 126.348 240.354 139.32 235.91 143.584C235.91 143.584 237.726 150.076 249.617 150.076C262.705 150.076 255.8 143.584 255.8 143.584C248.638 141.883 248.779 136.576 249.964 131.604L236.245 119.932L236.258 119.945Z"
            fill="#FFC3BD"
          />
          <path
            d="M232.857 146.147C230.989 146.212 233.565 140.222 234.403 139.681C236.348 138.431 258.647 139.423 257.925 142.94C257.578 144.447 256.547 147.333 255.426 148.209C254.293 149.085 249.166 145.246 232.857 146.147Z"
            fill="#263238"
          />
          <path
            d="M262.112 106.226C262.267 106.226 262.421 106.149 262.524 106.02C263.838 104.178 265.732 104.242 265.745 104.242C266.028 104.255 266.26 104.036 266.273 103.752C266.273 103.469 266.067 103.237 265.784 103.211C265.68 103.211 263.323 103.121 261.687 105.414C261.52 105.646 261.584 105.968 261.803 106.123C261.893 106.187 261.996 106.226 262.099 106.226H262.112Z"
            fill="#263238"
          />
          <path
            d="M265.023 112.371C265.023 112.371 266.389 116.171 267.961 118.039C266.775 119.069 264.843 118.502 264.843 118.502L265.023 112.371Z"
            fill="#FF5652"
          />
          <path
            d="M264.289 110.85C264.264 111.726 264.702 112.448 265.268 112.461C265.835 112.474 266.325 111.791 266.35 110.928C266.376 110.052 265.938 109.33 265.371 109.318C264.805 109.292 264.315 109.987 264.289 110.85Z"
            fill="#263238"
          />
          <path
            d="M265.114 109.356L267.226 108.841C267.226 108.841 266.106 110.425 265.114 109.356Z"
            fill="#263238"
          />
          <path
            d="M226.042 454.39H236.838L235.743 429.412H224.96L226.042 454.39Z"
            fill="#FFC3BD"
          />
          <path
            d="M237.675 453.141H225.553C225.115 453.141 224.754 453.45 224.702 453.875L223.414 463.472C223.285 464.464 224.149 465.405 225.166 465.392C229.392 465.314 231.427 465.07 236.76 465.07C240.045 465.07 244.837 465.405 249.359 465.405C253.88 465.405 254.087 460.922 252.193 460.522C243.716 458.706 242.364 456.194 239.491 453.798C238.976 453.373 238.332 453.141 237.675 453.141Z"
            fill="#263238"
          />
          <g opacity="0.2">
            <path
              d="M224.947 429.412H235.742L236.309 442.294H225.514L224.947 429.412Z"
              fill="black"
            />
          </g>
          <path
            d="M280.121 156.904C282.414 162.173 284.875 167.57 287.387 172.801C289.963 177.992 292.617 183.196 295.619 187.808C295.992 188.388 296.366 188.968 296.752 189.496L297.77 190.9C298.066 191.261 298.105 191.351 298.594 191.518C299.612 191.84 302.124 191.222 304.391 190.088C309.093 187.834 313.834 184.175 318.188 180.556L322.246 184.214C321.28 185.528 320.326 186.7 319.296 187.885C318.278 189.058 317.235 190.23 316.101 191.325C313.847 193.515 311.476 195.666 308.629 197.508C307.2 198.423 305.667 199.286 303.876 199.969C302.085 200.626 300.011 201.167 297.512 200.987C296.276 200.884 294.949 200.561 293.725 199.982C292.488 199.402 291.393 198.578 290.517 197.676C290.324 197.47 290.041 197.173 289.925 197.032L289.525 196.555L288.74 195.589C288.198 194.945 287.722 194.301 287.245 193.657C283.419 188.478 280.431 183.132 277.596 177.709C274.775 172.272 272.225 166.823 269.906 161.104L280.109 156.904H280.121Z"
            fill="#FFC3BD"
          />
          <path
            d="M318.291 180.246L323.379 177.799L326.407 185.85C326.407 185.85 318.858 188.491 315.264 185.541L315.444 184.163C315.676 182.462 316.745 180.994 318.291 180.246Z"
            fill="#FFC3BD"
          />
          <path
            d="M330.89 177.683L331.804 183.621L326.407 185.85L323.379 177.799L330.89 177.683Z"
            fill="#FFC3BD"
          />
          <path
            d="M235.124 106.715C235.472 117.42 235.382 121.955 240.612 127.649C248.483 136.215 261.558 133.587 264.418 123.024C266.994 113.517 266.003 97.6077 255.671 92.9444C245.494 88.3583 234.776 96.0103 235.111 106.715H235.124Z"
            fill="#FFC3BD"
          />
          <path
            d="M232.187 94.0007C236.696 85.8463 256.431 85.3955 263.117 94.5289C269.803 103.649 260.206 103.405 257.616 112.371C257.938 120.615 261.288 124.158 266.17 128.86C258.66 137.774 227.91 139.178 223.079 128.873C218.249 118.567 217.914 92.7125 232.187 94.0007Z"
            fill="#263238"
          />
          <path
            d="M232.548 94.9411C232.548 94.9411 233.269 91.6046 229.108 91.9267C230.976 92.9573 232.548 94.9411 232.548 94.9411Z"
            fill="#263238"
          />
          <path
            d="M255.71 111.675C255.864 113.749 256.934 115.733 258.222 117.047C260.141 119.018 262.112 117.871 262.408 115.54C262.692 113.44 262.022 109.884 259.69 108.764C257.397 107.656 255.529 109.292 255.71 111.675Z"
            fill="#FFC3BD"
          />
          <path
            d="M257.977 211.485C257.977 211.485 252.438 285.545 246.357 327.283C241.436 360.995 238.074 439.795 238.074 439.795H223.363C223.363 439.795 215.608 363.688 217.939 330.349C223.852 245.971 211.885 231.041 225.72 211.485H257.977Z"
            fill="#263238"
          />
          <path
            opacity="0.2"
            d="M241.269 255.336C240.973 273.023 247.452 289.332 250.402 294.73C251.987 280.276 253.417 265.191 254.615 251.884C249.939 236.812 241.54 238.654 241.256 255.336H241.269Z"
            fill="black"
          />
          <path
            d="M240.097 440.091H221.353L220.31 433.521L240.135 432.839L240.097 440.091Z"
            fill="#5E3377"
          />
          <path
            d="M242.518 435.183C242.467 435.106 242.454 435.016 242.493 434.926C242.531 434.822 242.634 434.771 242.75 434.771C243.291 434.81 248.109 435.235 248.856 436.549C248.998 436.806 249.011 437.077 248.869 437.36C248.637 437.811 248.29 438.095 247.826 438.172C246.28 438.442 243.858 436.433 242.557 435.209C242.557 435.209 242.531 435.183 242.518 435.17V435.183ZM248.38 436.755C247.877 436.059 245.378 435.57 243.472 435.351C245.301 436.961 246.873 437.811 247.736 437.657C248.032 437.605 248.264 437.425 248.418 437.116C248.483 437 248.483 436.897 248.418 436.793C248.418 436.781 248.393 436.755 248.38 436.742V436.755Z"
            fill="#5E3377"
          />
          <path
            d="M242.518 435.183C242.518 435.183 242.493 435.157 242.493 435.132C242.454 435.054 242.467 434.951 242.518 434.874C242.596 434.771 244.322 432.452 246.074 432.207C246.615 432.13 247.092 432.259 247.517 432.581C248.084 433.019 248.071 433.431 247.955 433.702C247.491 434.848 244.103 435.467 242.699 435.273C242.621 435.273 242.57 435.222 242.518 435.17V435.183ZM247.414 433.212C247.414 433.212 247.298 433.071 247.195 432.993C246.885 432.748 246.538 432.658 246.138 432.723C244.992 432.877 243.781 434.165 243.227 434.81C244.76 434.835 247.195 434.204 247.465 433.509C247.478 433.47 247.53 433.367 247.414 433.212Z"
            fill="#5E3377"
          />
          <path
            d="M231.414 430.752L241.359 435.041L245.584 425.844L251.085 413.799L251.729 412.408L241.784 408.105L241.063 409.689L235.717 421.348L231.414 430.752Z"
            fill="#FFC3BD"
          />
          <path
            opacity="0.2"
            d="M235.717 421.348L245.584 425.844L251.085 413.799L241.063 409.69L235.717 421.348Z"
            fill="black"
          />
          <path
            d="M269.558 211.485C269.558 211.485 286.421 285.094 286.794 322.967C287.129 357.208 249.758 422.301 249.758 422.301L236.258 416.182C236.258 416.182 260.476 337.807 258.067 326.832C251.072 294.987 239.246 226.351 239.234 211.485H269.571H269.558Z"
            fill="#263238"
          />
          <path
            d="M242.776 433.599L232.445 427.158C232.071 426.926 231.607 426.99 231.324 427.325L225.128 434.719C224.484 435.492 224.728 436.742 225.604 437.27C229.25 439.447 231.118 440.323 235.652 443.157C238.448 444.896 244.051 448.787 247.916 451.196C251.691 453.553 254.319 449.933 252.927 448.593C246.667 442.565 245.146 438.674 243.961 435.132C243.755 434.488 243.317 433.959 242.776 433.612V433.599Z"
            fill="#263238"
          />
          <path
            d="M250.737 425.071L233.346 417.921L235.189 410.9L253.816 418.527L250.737 425.071Z"
            fill="#5E3377"
          />
          <path
            d="M230.628 158.141C230.847 151.932 226.815 144.073 217.308 146.057C212.825 146.985 208.201 163.165 208.201 163.165L220.31 177.361C224.471 175.428 230.474 162.07 230.615 158.141H230.628Z"
            fill="#5E3377"
          />
          <path
            opacity="0.2"
            d="M222.319 159.802C222.319 159.802 222.435 168.099 220.271 177.296L220.336 177.361C222.809 176.214 225.926 171.023 228.078 166.192L222.319 159.802Z"
            fill="black"
          />
          <path
            d="M274.067 146.354C274.067 146.354 279.22 148.17 269.545 211.485H225.72C226.274 193.644 226.197 182.655 217.308 146.044C217.308 146.044 226.467 144.048 235.897 143.597C243.266 143.236 249.307 143.004 255.8 143.597C264.354 144.383 274.067 146.366 274.067 146.366V146.354Z"
            fill="#5E3377"
          />
          <path
            opacity="0.2"
            d="M273.771 168.034L266.995 168.897C267.42 174.037 270.73 180.182 273.397 183.441C273.951 178.726 274.363 174.617 274.672 171.036C274.196 169.181 273.771 168.034 273.771 168.034Z"
            fill="black"
          />
          <path
            d="M274.067 146.354C279.439 147.668 286.189 162.379 286.189 162.379L275.445 179.319C275.445 179.319 261.79 169.116 261.919 154.366C261.971 148.17 267.548 144.756 274.067 146.341V146.354Z"
            fill="#5E3377"
          />
          <path
            d="M225.05 208.909L223.118 212.838C222.963 213.147 223.337 213.456 223.839 213.456H269.803C270.202 213.456 270.524 213.263 270.55 213.018L270.911 209.089C270.936 208.819 270.589 208.587 270.164 208.587H225.785C225.463 208.587 225.166 208.716 225.076 208.922L225.05 208.909Z"
            fill="#5E3377"
          />
          <path
            opacity="0.2"
            d="M225.05 208.909L223.118 212.838C222.963 213.147 223.337 213.456 223.839 213.456H269.803C270.202 213.456 270.524 213.263 270.55 213.018L270.911 209.089C270.936 208.819 270.589 208.587 270.164 208.587H225.785C225.463 208.587 225.166 208.716 225.076 208.922L225.05 208.909Z"
            fill="white"
          />
          <path
            d="M231.053 213.869H229.868C229.636 213.869 229.456 213.753 229.469 213.598L229.971 208.497C229.984 208.342 230.19 208.226 230.422 208.226H231.607C231.839 208.226 232.02 208.342 232.007 208.497L231.504 213.598C231.491 213.753 231.285 213.869 231.053 213.869Z"
            fill="#263238"
          />
          <path
            d="M259.871 213.869H258.686C258.454 213.869 258.273 213.753 258.286 213.598L258.789 208.497C258.801 208.342 259.008 208.226 259.252 208.226H260.437C260.669 208.226 260.85 208.342 260.837 208.497L260.334 213.598C260.322 213.753 260.115 213.869 259.871 213.869Z"
            fill="#263238"
          />
        </g>
        <defs>
          <clipPath id="clip0_2535_31098">
            <rect width="644.105" height="480" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
}

export default LocationErrorIlustrate;
